// ########################## [IMPORTANT LIBRARIES]
import { useMemo } from 'react';
import styled from 'styled-components';

import { ColumnDef, createColumnHelper } from '@tanstack/react-table';

// ########################## [REDUX]
import { useSelector } from 'react-redux';
import {
  selectCarriersErrorMessages,
  selectCarriersErrorSuggestions,
} from '@web/features/ship/selectors';

// ########################## [TRANSLATIONS]
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { translations } from '@shippypro/translations';

// ########################## [DESIGN SYSTEM]
import { TooltipWrapperByID } from '@shippypro/design-system-web';

// ########################## [PAGE COMPONENTS]
import { WhatCanIDo } from '@web/features/ship/tabs/Error/components/popover/WhatCanIDo';

// ########################## [TYPES]
import { Order } from '@web/types/order';
import {
  CarrierErrorMessageSuggestion,
  CarrierErrorMessageSuggestionWithLinks,
  CarriersErrorMessages,
} from '@web/features/ship/types';

// ########################## [HOOKS]
import useGetTabColumnSize from '@web/features/ship/hooks/tabs/useGetTabColumnSize';

const columnHelper = createColumnHelper<Order>();

export const ErrorMessageColumnCode = 'errorMessage';

export const useGetErrorMessageColumn = (
  ignoreRowClick = false,
): ColumnDef<Order, string> => {
  const { t } = useTranslation(),
    transErrorTable = translations.ship.table,
    transError = transErrorTable.columns;

  const carriersErrorMessages = useSelector(selectCarriersErrorMessages());
  const carriersErrorSuggestions = useSelector(
    selectCarriersErrorSuggestions(),
  );

  const size = useGetTabColumnSize(ErrorMessageColumnCode, 400);

  return useMemo(
    () =>
      columnHelper.accessor('error_message', {
        header: t(transError.errorMessage),
        minSize: 150,
        size,
        maxSize: 600,
        meta: {
          ignoreRowClick: ignoreRowClick,
        },
        id: 'error-message',
        enableSorting: false,
        cell: ({ row: { original: order } }) =>
          errorMessageColumnRendererFn(
            t,
            order,
            carriersErrorMessages,
            carriersErrorSuggestions,
          ),
      }),
    [
      t,
      transError.errorMessage,
      carriersErrorMessages,
      carriersErrorSuggestions,
      size,
      ignoreRowClick,
    ],
  );
};

const errorMessageColumnRendererFn = (
  t: TFunction<'translation', undefined>,
  order: Order,
  carriersErrorMessages?: CarriersErrorMessages,
  carriersErrorSuggestions?: CarrierErrorMessageSuggestion,
) => {
  const target = `error-message-${order.id}`;
  const errorMessage: string =
    order.error_message ?? t(translations.common.notAvailableShort);

  // Get the error suggestion (if any)
  const errorSuggestion = getErrorMessageSuggestion(
    t,
    errorMessage,
    order.carrier_name,
    carriersErrorMessages,
    carriersErrorSuggestions,
  );

  // This is needed to not hide the suggestion popover link, as we crop very long messages in 2 lines
  const truncatedErrorMessage =
    errorMessage.length > 100
      ? `${errorMessage.substring(0, 97)}...`
      : errorMessage;

  return (
    <TooltipWrapperByID
      tooltipProps={{
        placement: 'top',
      }}
      target={target}
      text={errorMessage}
      checkOverflow
    >
      <ClampedErrorMessage className="text-[var(--shp-color-text-table-header)] mb-0 z-10 flex items-center flex-wrap">
        {errorSuggestion ? truncatedErrorMessage : errorMessage}{' '}
        {errorSuggestion && <WhatCanIDo suggestion={errorSuggestion} />}
      </ClampedErrorMessage>
    </TooltipWrapperByID>
  );
};

const getErrorMessageSuggestion = (
  t: TFunction<'translation', undefined>,
  message: string,
  carrierName: string | null,
  carriersErrorMessages?: CarriersErrorMessages,
  carriersErrorSuggestions?: CarrierErrorMessageSuggestion,
): CarrierErrorMessageSuggestionWithLinks | null => {
  // No suggestion for not supported carriers
  if (
    !carrierName ||
    !carriersErrorMessages ||
    !carriersErrorMessages[carrierName] ||
    !carriersErrorSuggestions
  )
    return null;

  // Find the matching error message in the carrier list
  const carrierErrorsList = carriersErrorMessages[carrierName];
  const messageObj = carrierErrorsList
    ? carrierErrorsList.find(e =>
        message.match(
          new RegExp(e.message.replace(/([.?*+^$[\]\\(){}|-])/g, '\\$1')),
        ),
      )
    : null;

  // No suggestion if we don't find a match
  if (!messageObj) return null;

  // Gets the suggestion's translations
  const suggestionTransObj =
    carriersErrorSuggestions[messageObj.suggestionId] ?? null;

  // This is a guard, in case we forgot/mispell the translation key :) -> no suggestion, should never happen
  /* istanbul ignore next */
  if (!suggestionTransObj) return null;

  return {
    description: t(suggestionTransObj.description, { carrier: carrierName }),
    links: messageObj.links?.length > 0 ? messageObj.links : null,
    proposedAction: t(suggestionTransObj.proposedAction, {
      carrier: carrierName,
    }),
  };
};

// Clamp the error message on two lines
const ClampedErrorMessage = styled.p`
  overflow: hidden;
  max-width: 100%;
  text-wrap: wrap;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;
